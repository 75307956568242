.App {
  text-align: center;
  background: none;
  background-color: black;
  color: white;
  font-family: "Indie Flower", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: min(5vw, 36px);
}

.App h1 {
  font-family: "Luckiest Guy", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: min(14vw, 128px);
}

.Nav {
  background-color: black;
  color: white;
  height: 50px;
  padding-top: 10px;
  position: fixed;
  width: 100vw;
  box-shadow: 0 0px 4px -2px white;
}

.Nav h1 {
  margin-block-start: 0;
  margin-block-end: 0;
  line-height: 50px;
  font-size: 36px;
}

.Banner {
  padding-top: 60px;
}
.Banner img{
  width: 100%;
}

.Filler {
  padding: 15vw;
  width: 70%;
  margin: 0 auto;
}
.Meme-Container {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;

}

.Meme-Container img {
  width: 35vw;
  max-width: 450px;
  min-width: 340px;
  padding: 1vw;
  border-radius: 30px;
}

.Links-Section {
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.Links-Section .Rectangles {
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.Links-Section .Circles {
  padding-top: 5vh;
  width: 40%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

#x-image {
  padding-top: 0.5vw;
  height: 7vw;
}


.Links-Section .Rectangles img {
  height: 5vw;
}

.Links-Section .Circles img {
  height: 9vw;
}

.Clickable-Image-Container {
  display: flex;
  justify-content: space-around;
}

.Clickable-Image-Container .Left {
  width: 50%;
}

.Clickable-Image-Container .Left img {
  width: 50%;
  padding-top: 20px;

}

.Clickable-Image-Container .Right {
  width: 50%;
  padding-top: 5%;
}

.Clickable-Image-Container .Right img{
  width: 30%;
  display: inline-block;
}

#telegramLogo {
  width: 20%;
}
.Clickable-Image-Container img {
}

.Clickable-Image img:hover {
  cursor: pointer;
  -webkit-filter: brightness(70%);
}

.Video {
  width: 100vw;
  height:calc(100vw* (9/16));
  margin: 0 auto;
}